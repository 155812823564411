.Header {
  display: flex;
  align-items: center;
  padding: 12px 48px;
}

.ConnectButton {
  margin-left: auto;
}

.NavList {
  margin-left: 96px;
  display: flex;
  gap: 28px;
}