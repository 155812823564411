* {
  box-sizing: border-box;
}

html, body {
  overflow-x: hidden;
  padding: 0 !important;
  margin: 0;
}

/* Scrollbar */

/* width */
::-webkit-scrollbar {
  width: 16px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #FBFDFF;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border: 4px solid transparent;
  background: rgba(33, 150, 243, 0.14);
  border-radius: 100px;
  background-clip: content-box;
}